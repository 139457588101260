import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { message } from "antd";
import {
  getAllBatches,
  getBatchData,
  getNewPaymentBatch,
  updateBatchStatusReq,
  refundEmailsReq,
} from "@iso/lib/apiHelpers/batchesPayReq";
import actions from "./actions";
import { createBrowserHistory } from "history";
const history = createBrowserHistory();

export function* getAllBatchesEffect() {
  yield takeEvery(actions.GET_BATCHES, function* () {
    try {
      const response = yield call(getAllBatches);
      console.log(response);
      yield put({
        type: actions.GET_BATCHES_SUCCESS,
        payload: response,
      });
      message.success("All Batch Data is Fetched");
    } catch (error) {
      console.log(error);
      yield put({
        type: actions.GET_BATCHES_FAILED,
        payload: error.message,
      });
      message.error(error.message);
    }
  });
}
export function* getBatchDataEffect() {
  yield takeEvery(actions.GET_BATCH_DATA, function* ({ payload }) {
    try {
      const response = yield call(getBatchData, payload);
      console.log(response);
      yield put({
        type: actions.GET_BATCH_DATA_SUCCESS,
        payload: response,
      });
      message.success("Batch Data Fetched");
    } catch (error) {
      console.log(error);
      yield put({
        type: actions.GET_BATCH_DATA_FAILED,
        payload: "Error Occured",
      });
      message.error("Error Occured");
    }
  });
}

export function* getNewPaymentBatchEffect() {
  yield takeEvery(actions.GET_NEW_BATCH, function* ({ payload }) {
    try {
      const response = yield call(getNewPaymentBatch, payload);
      console.log(response);
      history.push(`/dashboard/binancePay/${response.id}`);
      window.location.reload();

      message.success("New Batch Data Created");
    } catch (error) {
      console.log(error);
      yield put({
        type: actions.GET_NEW_BATCH_FAILED,
        payload: "Error Occured",
      });
      message.error("Error Occured");
    }
  });
}

export function* updateBatchStatusEffect() {
  yield takeEvery(actions.UPDATE_BATCH_STATUS, function* ({ payload }) {
    try {
      const response = yield call(updateBatchStatusReq, payload);
      console.log(response);
      yield put({
        type: actions.UPDATE_BATCH_STATUS_SUCCESS,
        payload: response,
      });
      message.success("Batch Status Updated");
    } catch (error) {
      console.log(error);
      yield put({
        type: actions.UPDATE_BATCH_STATUS_FAILED,
        payload: "Error Occured",
      });
      message.error("Error Occured");
    }
  });
}

export function* refundEmailsEffect() {
  yield takeEvery(actions.REFUND_EMAILS, function* ({ payload }) {
    try {
      const response = yield call(refundEmailsReq, payload);
      console.log(response);
      yield put({
        type: actions.REFUND_EMAILS_SUCCESS,
        payload: response,
      });
      message.success("Refund Success");
    } catch (error) {
      console.log(error);
      yield put({
        type: actions.REFUND_EMAILS_FAILED,
        payload: "Error Occured",
      });
      message.error("Refund Failed");
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getAllBatchesEffect),
    fork(getBatchDataEffect),
    fork(getNewPaymentBatchEffect),
    fork(updateBatchStatusEffect),
    fork(refundEmailsEffect),
  ]);
}
