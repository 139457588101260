const actions = {
  GET_BATCHES: "GET_BATCHES",
  GET_BATCHES_SUCCESS: "GET_BATCHESSUCCESS",
  GET_BATCHES_FAILED: "GET_BATCHES_FAILED",
  GET_NEW_BATCH: "GET_NEW_BATCH",
  GET_NEW_BATCH_SUCCESS: "GET_NEW_BATCH_SUCCESS",
  GET_NEW_BATCH_FAILED: "GET_NEW_BATCH_FAILED",
  GET_BATCH_DATA: "GET_BATCH_DATA",
  GET_BATCH_DATA_SUCCESS: "GET_BATCH_DATA_SUCCESS",
  GET_BATCH_DATA_FAILED: "GET_BATCH_DATA_FAILED",
  UPDATE_BATCH_STATUS: "UPDATE_BATCH_STATUS",
  UPDATE_BATCH_STATUS_SUCCESS: "UPDATE_BATCH_STATUS_SUCCESS",
  UPDATE_BATCH_STATUS_FAILED: "UPDATE_BATCH_STATUS_FAILED",
  REFUND_EMAILS: "REFUND_EMAILS",
  REFUND_EMAILS_SUCCESS: "REFUND_EMAILS_SUCCESS",
  REFUND_EMAILS_FAILED: "REFUND_EMAILS_FAILED",

  getAllBatches: () => ({
    type: actions.GET_BATCHES,
  }),
  getNewBatch: (payload) => ({
    type: actions.GET_NEW_BATCH,
    payload,
  }),
  getBatchData: (payload) => ({
    type: actions.GET_BATCH_DATA,
    payload,
  }),
  updateBatchStatus: (payload) => ({
    type: actions.UPDATE_BATCH_STATUS,
    payload,
  }),
  refundEmails: (payload) => ({
    type: actions.REFUND_EMAILS,
    payload,
  }),
};
export default actions;
