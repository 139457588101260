import actions from "./actions";

const initialState = {
  error: false,
  loading: false,
  allBatches: [],
  batchDetails: {},
  batchEmails: [],
};

export default function binancePay(state = initialState, action) {
  switch (action.type) {
    case actions.GET_BATCHES:
    case actions.GET_NEW_BATCH:
    case actions.GET_BATCH_DATA:
    case actions.UPDATE_BATCH_STATUS:
    case actions.REFUND_EMAILS:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_BATCHES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        allBatches: action.payload,
      };
    case actions.GET_NEW_BATCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        allBatches: [action.payload, ...state.allBatches],
      };
    case actions.GET_BATCH_DATA_SUCCESS:
      const { batchDetails, batchEmails } = action.payload;
      return {
        ...state,
        loading: false,
        error: false,
        batchDetails,
        batchEmails,
      };
    case actions.UPDATE_BATCH_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        batchDetails: { ...state.batchDetails, status: action.payload.status },
      };
    case actions.REFUND_EMAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case actions.GET_BATCHES_FAILED:
    case actions.GET_NEW_BATCH_FAILED:
    case actions.GET_BATCH_DATA_FAILED:
    case actions.UPDATE_BATCH_STATUS_FAILED:
    case actions.REFUND_EMAILS_FAILED:
      return {
        ...initialState,
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
}
