import { axiosInstance } from "./axiosInstance";

export const getAllBatches = () => {
  return axiosInstance({
    method: "GET",
    url: `/payment/getAllBatches`,
  });
};
export const getBatchData = (payload) => {
  return axiosInstance({
    method: "POST",
    url: `/payment/getBatchData`,
    data: JSON.stringify(payload),
  });
};
export const getNewPaymentBatch = (payload) => {
  return axiosInstance({
    method: "POST",
    url: `/payment/getNewPaymentBatch`,
    data: JSON.stringify(payload),
  });
};
export const updateBatchStatusReq = (payload) => {
  return axiosInstance({
    method: "POST",
    url: `/payment/updateBatchStatus`,
    data: JSON.stringify(payload),
  });
};

export const refundEmailsReq = (payload) => {
  return axiosInstance({
    method: "POST",
    url: `/payment/refundEmails`,
    data: JSON.stringify(payload),
  });
};
